
import './bootstrap';
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

import sort from '@alpinejs/sort'
import collapse from '@alpinejs/collapse'

// Register any Alpine directives, components, or plugins here...
Alpine.plugin(sort)
Alpine.plugin(collapse)
 
Livewire.start()
// import 'livewire-sortable'

// function resizeTextarea(textarea) {
//     textarea.style.height = 'auto';
//     textarea.style.height = 25 + textarea.scrollHeight + 'px';
// }

// function resizeTextareaById(id) {
//     var textarea = document.getElementById(id);
//     if (textarea) {
//         textarea.style.height = 'auto'; // Reset height to auto to recalculate
//         textarea.style.height = 25 + textarea.scrollHeight + 'px'; // Set the new height
//     } else {
//         console.warn(`Textarea with ID "${id}" not found.`);
//     }
// }

// // Initialize on page load for existing content
// document.addEventListener('DOMContentLoaded', function() {
//     document.querySelectorAll('*[data-autosize]').forEach(function(textarea) {
//         resizeTextarea(textarea);
//     });
// });


// import autosize from 'autosize';
// autosize(document.querySelectorAll('*[data-autosize]'));

// TinyMCE - https://laracoding.com/how-to-add-tinymce-using-laravel-10-and-vite/
// import 'tinymce/tinymce';
// import 'tinymce/skins/ui/oxide/skin.min.css';
// import 'tinymce/skins/content/default/content.min.css';
// import 'tinymce/skins/content/default/content.css';
// import 'tinymce/icons/default/icons';
// import 'tinymce/themes/silver/theme';
// import 'tinymce/models/dom/model';

// .. After imports init TinyMCE ..
// window.addEventListener('DOMContentLoaded', () => {
//     tinymce.init({
//        mode: "none",
//        menubar: false,
//     });
// });